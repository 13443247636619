import React, { useState } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import visual from '../images/up.jpg'
import pattern from '../images/pattern-contact.jpg'
import Thanks from '../svg/thanks-circle.svg'
import PageHeader from '../components/PageHeader'
import Button from '../components/Button'
import styled from 'styled-components'
import {LeftSection, RightSection, PageWrapper, Row, Span, Title, Flex} from '../components/UI'
import FormItem from '../components/FormItem'
import _ from 'lodash'
import {breakpoints} from '../utils/helper'
import axios from 'axios'
import {OutboundLink} from 'gatsby-plugin-gtag'

const CustomTitle = styled(Title)`
    color: ${props => props.theme.colors.white};
    
    b {
        color: ${props => props.theme.colors.red};    
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: 100%;
    
    button {
        width: 128px;
        margin-top: 48px;
    }
`

const Terms = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

const Label = styled.label`
    margin-left: 12px;
    font-size: 0.75rem;
    color: ${props => props.error ? props.theme.colors.red : props.theme.colors.black };
`

const Link = styled(OutboundLink)`
    color: ${props => props.error ? props.theme.colors.red : props.theme.colors.black };
`

const MyRow = styled(Row)`
    margin-bottom: 90px;
    flex-direction: column;
    .text {
        width: 100%;
    }
    @media ${breakpoints.desktop} {
        flex-direction: row;    
    }
`

const ThanksBox = styled(Flex)`
    align-items: flex-start;
    justify-content: center;
    //height: 240px;
    width: 80%;
    flex-direction: column;
    
    svg {
        flex-shrink: 0;
        margin-bottom: 42px;
    }
    
    h2 {
        text-align: left;
        font-size: 2rem;
        line-height: 2.25rem;
        margin-bottom: 24px;
        color: ${props => props.theme.colors.red};
    }
`

const UPPage = ({location}) => {
    const [statusCode, setStatusCode] = useState(null)
    const [showError, setShowError] = useState(false)
    const [formItems, setFormItems] = useState({
        first_name: '',
        last_name: '',
        email: '',
        license: '',
        terms: false
    })

    const handleChange = (e) => {
        setShowError(false)
        setFormItems({
            ...formItems,
            [e.target.name]: e.target.name !== 'terms' ? e.target.value : e.target.checked
        })
    }

    const hasEntry = (val) => !_.isEmpty(val)

    const handleSubmit = (e) => {
        e.preventDefault()
        signup(_.get(formItems, 'first_name'),
            _.get(formItems, 'last_name'),
            _.get(formItems, 'email'),
            _.get(formItems, 'license'),
            _.get(formItems, 'terms'))
    }

    const signup = async (qrcode, firstname, lastname, email, license, terms) => {
        setShowError(true)

        const valid = validEmail(email)
            && hasEntry(firstname)
            && hasEntry(lastname)
            && terms

        if (valid) {
            try {
                const res = await axios.post(
                    'https://api.upcarwash.nl/register-card',
                    { qrcode: qrcode, firstname: firstname, lastname: lastname, email: email, license: license })
                setShowError(false)
                setStatusCode(_.get(res, 'status'))
            } catch ({response}) {
                console.log(_.get(response, 'status', 500))
                setStatusCode(_.get(response, 'status', 500))
            }
        }
    }

    const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    const emailError = () => {
        const email = _.get(formItems, 'email')
        switch(true) {
            case _.isEmpty(email):
                return 'Er is geen e-mailadres ingevuld.'
            case !validEmail(email):
                return 'Het ingevulde e-mailadres is ongeldig'
            default:
                return ''
        }
    }

    return (
        <Layout location={location}>
            <SEO title="UP Club" description="De UP Club is een gratis lidmaatschap voor de hele wasfamilie."/>
            <PageHeader image={visual} backgroundColor="#333333" textColor="#FFFFFF" imageWidth="520px" pattern={pattern}>
                <CustomTitle>
                    COPY COPY<br/>COPY COPY<br/>COPY!
                </CustomTitle>
            </PageHeader>
            <PageWrapper>
                <MyRow>
                    <LeftSection className="text">
                        { !_.isNull(statusCode) && (statusCode <= 299 || statusCode === 409)
                            ? <ThanksBox>
                                <Thanks/>
                                <h2>Bedankt voor je het registreren van je membercard!</h2>
                                <p>
                                    [COPY over het ontvangen je UP Club membercard en dat je oude pas niet meer geldig is]
                                </p>
                            </ThanksBox>
                            : <>
                                <Span>
                                    [INTRO COPY]
                                </Span>
                                <Form onSubmit={handleSubmit} novalidate>
                                    <FormItem
                                        name="qrcode"
                                        label="*pasnummer"
                                        placeholder="Je pasnummer"
                                        type="text"
                                        error="Er is geen pasnummer ingevuld."
                                        toggleError={showError && _.isEqual(_.get(formItems, 'qrcode'), '')}
                                        value={_.get(formItems, 'qrcode')}
                                        onChange={handleChange}
                                    />
                                    <FormItem
                                        name="first_name"
                                        label="*voornaam"
                                        placeholder="John"
                                        type="text"
                                        error="Er is geen voornaam ingevuld."
                                        toggleError={showError && _.isEqual(_.get(formItems, 'first_name'), '')}
                                        value={_.get(formItems, 'first_name')}
                                        onChange={handleChange}
                                    />
                                    <FormItem
                                        name="last_name"
                                        label="*achternaam"
                                        placeholder="Doe"
                                        type="text"
                                        error="Er is geen achternaam ingevuld."
                                        toggleError={showError && _.isEqual(_.get(formItems, 'last_name'), '')}
                                        value={_.get(formItems, 'last_name')}
                                        onChange={handleChange}
                                    />
                                    <FormItem
                                        name="email"
                                        label="*e-mailadres"
                                        placeholder="info@email.nl"
                                        type="text"
                                        error={emailError()}
                                        toggleError={showError}
                                        value={_.get(formItems, 'email')}
                                        onChange={handleChange}
                                    />
                                    <FormItem
                                        name="license"
                                        label="kenteken (optioneel)"
                                        placeholder="kenteken"
                                        type="text"
                                        value={_.get(formItems, 'license')}
                                        onChange={handleChange}
                                    />
                                    <Button>Registreren</Button>
                                </Form>
                            </>
                        }
                    </LeftSection>
                    <RightSection>

                    </RightSection>
                </MyRow>
            </PageWrapper>
        </Layout>
    )
}

export default UPPage
